import { render, staticRenderFns } from "./workerEdu.vue?vue&type=template&id=77925940&scoped=true&"
import script from "./workerEdu.vue?vue&type=script&lang=js&"
export * from "./workerEdu.vue?vue&type=script&lang=js&"
import style0 from "./workerEdu.vue?vue&type=style&index=0&id=77925940&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77925940",
  null
  
)

export default component.exports